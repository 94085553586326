import Header from "../components/Util/Header";
import { useState } from "react";
import { useEffect } from "react";
import UserBox from "../components/Election/UserBox";
import "../Styles/election.scss";
import WaveLoading from "../components/Util/WaveLoading";

export default function Election(props) {
  const [offset, setOffset] = useState(0);
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [path, setPath] = useState(null);
  const [chairperson, setChairperson] = useState(null);
  const [treasurer, setTreasurer] = useState(null);
  const [secretary, setSecretary] = useState(null);
  const [loading, setLoading] = useState(null);
  const [selC, setSelC] = useState(null);
  const [selT, setSelT] = useState(null);
  const [selS, setSelS] = useState(null);
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [voted, setVoted] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("fmgdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setCurrentUser(decoded);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetch(`/api/voters/candidates/Chairperson/${currentUser?.Market}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setChairperson(data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetch(`/api/voters/candidates/Treasurer/${currentUser?.Market}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTreasurer(data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetch(`/api/voters/candidates/Secretary/${currentUser?.Market}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setSecretary(data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [currentUser]);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  function endorseCandidate(body) {
    fetch(`/api/tally/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        if (data?.success) {
          setError(data.success);
          setRefresh(!refresh);
        } else setError(data.error);
      })
      .catch((e) => {
        setError("failed");
      });
  }

  return (
    <div className="election">
      <Header showing={showing} setShowing={setShowing} />
      <div className="container">
        <div className="user-list">
          <div className="bar">
            <h4>{currentUser?.Market} Market Chairperson Election</h4>
          </div>
          <hr />
          <br />

          {chairperson && chairperson?.length > 0 ? (
            <div className="list">
              {chairperson?.map((item, index) => {
                return (
                  <UserBox
                    key={index}
                    item={item}
                    index={index}
                    offset={offset}
                    userDetails={currentUser}
                    active={selC}
                    setActive={setSelC}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    voted={voted}
                    setVoted={setVoted}
                  />
                );
              })}
            </div>
          ) : (
            <p className="msg">
              No candidates have signed up for this category
            </p>
          )}
        </div>
        <br />
        <div className="user-list" style={{ backgroundColor: "#EEEEEE" }}>
          <div className="bar">
            <h4>{currentUser?.Market} Treasurer Market Election</h4>
          </div>
          <hr />
          <br />

          {treasurer && treasurer?.length > 0 ? (
            <div className="list">
              {treasurer?.map((item, index) => {
                return (
                  <UserBox
                    key={index}
                    item={item}
                    index={index}
                    offset={offset}
                    userDetails={currentUser}
                    active={selT}
                    setActive={setSelT}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    voted={voted}
                    setVoted={setVoted}
                  />
                );
              })}
            </div>
          ) : (
            <p className="msg">
              No candidates have signed up for this category
            </p>
          )}
        </div>
        <br />
        <div className="user-list" style={{ backgroundColor: "#E0FBE2" }}>
          <div className="bar">
            <h4>{currentUser?.Market} Market Secretary Election</h4>
          </div>
          <hr />
          <br />

          {secretary && secretary?.length > 0 ? (
            <div className="list">
              {secretary?.map((item, index) => {
                return (
                  <UserBox
                    key={index}
                    item={item}
                    index={index}
                    offset={offset}
                    userDetails={currentUser}
                    setActive={setSelS}
                    active={selS}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    voted={voted}
                    setVoted={setVoted}
                  />
                );
              })}
            </div>
          ) : (
            <p className="msg">
              No candidates have signed up for this category
            </p>
          )}
        </div>
        <br />
        <h6>{error}</h6>

        <button
          className="button"
          onClick={() => {
            if (selC) {
              endorseCandidate({
                Type: "Election",
                CandidateID: selC,
                VoterID: currentUser?.UserID,
              });
            } else setError("Select a Chairperson");
            if (selT) {
              endorseCandidate({
                Type: "Election",
                CandidateID: selT,
                VoterID: currentUser?.UserID,
              });
            } else setError("Select a Treasurer");
            if (selS) {
              endorseCandidate({
                Type: "Election",
                CandidateID: selS,
                VoterID: currentUser?.UserID,
              });
            } else setError("Select a Secretary");
          }}
        >
          Submit
        </button>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Options to format the date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Format the date using toLocaleDateString with the options
  return date.toLocaleDateString("en-US", options);
};
