import React from "react";
import logo from "../assets/imgs/logo.png";
import "../Styles/terms.scss";
import Header from "../components/Util/Header";

class Terms extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="terms">
          <div className="container">
            <h1>Terms and Conditions</h1>
            <p>Last Updated: 27/9/2023</p>
            <h3>1. Acceptance of Terms</h3>
            <p>
              Welcome to Kajiado Market Polls' system (the "Service"). By
              accessing or using this Service, you agree to comply with and be
              bound by these Terms and Conditions. If you do not agree with
              these terms, please do not use the Service.
            </p>

            <h3>2. Service Description</h3>
            <p>
              Our system provide real-time location tracking and management
              capabilities for Cleaners. This system is designed to enhance
              cleaner management and efficiency.
            </p>

            <h3>3. User Registration</h3>
            <p>
              To use our Service, you may be required to register for an
              account. You must provide accurate and complete information during
              the registration process and update it as necessary. You are
              responsible for maintaining the confidentiality of your account
              credentials and for all activities that occur under your account.
            </p>

            <h3>4. Privacy</h3>
            <p>
              Your use of the Service is also governed by our Privacy Policy,
              which can be found https://secuwatch.dat.co.ke. By using the
              Service, you consent to the collection, use, and disclosure of
              your information as described in the Privacy Policy.
            </p>
            <h3>5. Location Tracking</h3>
            <p>
              The Mobile App utilizes real-time location tracking technology to
              monitor the whereabouts of Cleaners. By using the Mobile App,
              Cleaners consent to the collection and sharing of their location
              data with their employers or authorized personnel.
            </p>
            <h3>6. Data Security</h3>
            <p>
              We take data cleaner seriously and implement reasonable measures
              to protect your data. However, you acknowledge that no system is
              entirely secure, and we cannot guarantee the absolute cleaner of
              your information.
            </p>
            <h3>7. User Responsibilities</h3>
            <p>
              You agree not to misuse the Service, including but not limited to:
              <ul>
                <li>Attempting to access data not intended for you.</li>
                <li>
                  Uploading or sharing any content that is illegal, harmful, or
                  violates any third-party rights.
                </li>
                <li>Disrupting the Service's functionality.</li>
              </ul>
            </p>
            <h3>8. Termination</h3>
            <p>
              We reserve the right to terminate or suspend your access to the
              Service at our discretion, with or without notice, for any reason,
              including if you violate these Terms and Conditions.
            </p>
            <h3>9. Changes to Terms</h3>
            <p>
              We may update or modify these Terms and Conditions at any time
              without notice. Your continued use of the Service after such
              changes will constitute your acceptance of the updated terms.
            </p>
            <h3>10. Disclaimer</h3>
            <p>
              The Service is provided "as is" and "as available" without
              warranties of any kind, either expressed or implied. We do not
              guarantee the accuracy, reliability, or availability of the
              Service.
            </p>
            <h3>11. Limitation of Liability</h3>
            <p>
              To the extent permitted by law, we shall not be liable for any
              direct, indirect, incidental, consequential, or punitive damages
              arising from your use of the Service.
            </p>
            <h3>12. Governing Law</h3>
            <p>
              These Terms and Conditions are governed by the laws of Kenya. Any
              disputes arising from or relating to the Service shall be subject
              to the exclusive jurisdiction of the courts in Kenya.
            </p>
            <h3>13. Contact Us</h3>
            <p>
              If you have any questions or concerns about these Terms and
              Conditions, please contact us at info@secuwatch.dat.co.ke.
            </p>
            <p>
              By using our system, you acknowledge that you have read,
              understood, and agreed to these Terms and Conditions.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Terms;
