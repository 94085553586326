import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
// importing pages
import NotFound from "./Pages/404";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import Terms from "./Pages/Terms";
import PrivacyPolicyPage from "./Pages/Privacy";
import Election from "./Pages/Election";
// importing pages

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/privacy" element={<PrivacyPolicyPage />} />

        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/" element={<ProtectedRoute component={Home} />} />
        <Route path="/election" element={<ProtectedRoute component={Election} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
