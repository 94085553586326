import Header from "../components/Util/Header";
import { useState } from "react";
import { useEffect } from "react";
import UserBox from "../components/Candidates/UserBox";
import "../Styles/home.scss";
import { BsShop } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import Loading from "../components/Util/Loading";
import WaveLoading from "../components/Util/WaveLoading";

export default function Home(props) {
  const [offset, setOffset] = useState(0);
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [path, setPath] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [activity, setActivity] = useState(null);
  const [election, setElection] = useState(null);
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const [active, setActive] = useState("Chairperson");

  useEffect(() => {
    const token = localStorage.getItem("fmgdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setCurrentUser(decoded);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    fetch(`/api/tally/getactivity/${currentUser?.UserID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then(async (data) => {
        console.log(data);
        setActivity(data);
      })
      .catch((e) => {});
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetch(`/api/elections/getbymarket/${currentUser?.Market}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then(async (data) => {
          console.log(data);
          setElection(data);
        })
        .catch((e) => {});
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      fetch(`/api/voters/candidates/${active}/${currentUser.Market}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [offset, active, currentUser]);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  return (
    <div className="home">
      <Header showing={showing} setShowing={setShowing} />
      <div className="container">
        <div className="main">
          <div className="user-list">
            <div className="bar">
              <h4>Candidates</h4>
              <p
                onClick={() => {
                  setActive("Chairperson");
                }}
                className={active == "Chairperson" ? "active" : ""}
              >
                Chairperson
              </p>
              <p
                onClick={() => {
                  setActive("Treasurer");
                }}
                className={active == "Treasurer" ? "active" : ""}
              >
                Treasurer
              </p>
              <p
                onClick={() => {
                  setActive("Secretary");
                }}
                className={active == "Secretary" ? "active" : ""}
              >
                Secretary
              </p>
            </div>
            <hr />
            <br />

            {data && data?.length > 0 ? (
              <div className="list">
                {data?.map((item, index) => {
                  return (
                    <UserBox
                      key={index}
                      item={item}
                      index={index}
                      offset={offset}
                      userDetails={currentUser}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="msg">
                No candidates have signed up for this category
              </p>
            )}
          </div>
          <br />
          <div className="ot">
            <h1>
              Vote for <b>{currentUser?.Market}</b> <br /> market leaders
            </h1>
            <div className="div2equal">
              <button
                onClick={() => {
                  window.location.href = "/election";
                }}
              >
                Vote Now!
              </button>
              <div className="left">
                {election && election.length > 0 ? (
                  <div>
                    <h4>{formatDate(election[0]?.StartDate.split("T")[0])}</h4>
                    <FontAwesomeIcon className="ic" icon={faEllipsis} />
                    <h4>{formatDate(election[0]?.EndDate.split("T")[0])}</h4>
                  </div>
                ) : (
                  <div></div>
                )}
                <br />
                <p>All elections will run from 6.00 a.m. to 6.00 p.m.</p>
              </div>
            </div>
            <div className="status">
              {election && election.length > 0
                ? election[0].Status && election[0].Open
                  ? "Open"
                  : "Closed"
                : "Election not created!"}
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div className="section">
            <div className="divauto1">
              <BsShop color="blue" size={24} />
              <h2>{currentUser?.Market}</h2>
            </div>

            <hr />
            <h1>{currentUser?.Category}</h1>
          </div>
          <br />
          <div className="section">
            <h4>Activity</h4>
            <hr />
            {activity &&
              activity.length > 0 &&
              activity.map((item, i) => {
                return <Activity key={i} item={item} />;
              })}
          </div>
          <br />
          <div className="section">
            <h4>News Feed</h4>
            <hr />
          </div>
        </div>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Candidates = (props) => {};

const Activity = (props) => {
  return (
    <div className="activity">
      <p>
        {formatDate(props.item.Date)} -{" "}
        {props.item.Date.split("T")[1].substring(0, 5)}
      </p>
      <h4>
        <b>{props.item.Type}</b> for <b>{props.item.Name}</b> who is vying for{" "}
        <b>{props.item.Position}</b> Position at {props.item.Market}
      </h4>
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Options to format the date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Format the date using toLocaleDateString with the options
  return date.toLocaleDateString("en-US", options);
};
