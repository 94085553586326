import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/imgs/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {
  var jwt = require("jsonwebtoken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [clicked, setClicked] = useState(false);

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("fmgdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  return (
    <div className="header">
      <div className="cont_header">
        <img src={logo} alt="" />

        <div className="mid">
          <p
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Home
          </p>
          <p
            onClick={() => {
              window.location.href = "/terms";
            }}
          >
            Terms
          </p>
          <p
            onClick={() => {
              window.location.href = "/privacy";
            }}
          >
            Privacy
          </p>
        </div>

        <div
          onClick={() => {
            localStorage.clear();
            window.location.href = "/login";
          }}
          className="right"
        >
          <p>Logout</p>
          <FontAwesomeIcon className="fa fa-user" icon={faSignOut} />
        </div>
      </div>
    </div>
  );
}
