import {
  faAddressBook,
  faEnvelope,
  faLocation,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { MdFemale, MdMale } from "react-icons/md";
import placeholder from "../../assets/imgs/placeholder.png";

export default function UserBox(props) {
  const [photo, setPhoto] = useState(placeholder);

  useEffect(() => {
    fetch(`/api/uploads/${props.item.Pass_Photo}`)
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const img = await blobToBase64(data);
        setPhoto(`data:image/png;base64,${img}`);
      })
      .catch((e) => {});
  }, [props.item]);

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Get the Base64 string from the data URL
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    fetch(
      `/api/tally/checkendorsed/Election/${props?.userDetails?.UserID}/${props?.item?.UserID}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then(async (data) => {
        if (data) {
          if (!props.voted) {
            props.setVoted(true);
          }
        }
      })
      .catch((e) => {});
  }, [props.item, props.refresh]);

  useEffect(() => {
    fetch(`/api/tally/countall/Election/${props?.item?.UserID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then(async (data) => {
        if (data.total > 0) {
          props.setVoted(true);
        }
      })
      .catch((e) => {});
  }, [props.item, props.refresh]);

  return (
    <div
      className={
        props.userID == props?.item?.UserID ? "user-box active" : "user-box"
      }
    >
      <div className="left">
        <h2>{props.index + props.offset + 1}</h2>
      </div>
      <img src={photo} alt="" />
      <div className="right">
        <h2 className="title">{props?.item?.Name}</h2>
        <h3>
          <b>Category: </b> {props?.item?.Category}
        </h3>
        <h3>
          <b>Position: </b> {props?.item?.Position}
        </h3>
        <p>
          <b>Market: </b>
          {props?.item?.Market}
        </p>
      </div>
      {props.voted ? (
        <div className="nom">
          <p>You have voted!</p>
        </div>
      ) : (
        <div className="nom">
          <p>Vote</p>
          <input
            onChange={(e) => {
              if (e.target.checked) {
                props.setActive(props.item.UserID);
              } else props.setActive(null);
            }}
            checked={props.active == props.item.UserID ? true : false}
            type="checkbox"
            name=""
            id=""
          />
        </div>
      )}

      {props?.item?.Gender == "Female" ? (
        <MdFemale color="purple" className="gd" />
      ) : (
        <MdMale color="blue" className="gd" />
      )}
    </div>
  );
}
