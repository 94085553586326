import "../Styles/login.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../components/Util/Loading";
import Input from "../components/Util/Input";
import Button from "../components/Util/Button";
import Select from "../components/Util/Select";

export default function Login(props) {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
  });
  const ref = useRef();
  const [token, setToken] = useState(null);
  const [active, setActive] = useState("login");
  const [sactive, setSActive] = useState("reset");
  const [leftA, setLeftA] = useState("100%");
  const [rightA, setRightA] = useState("-100%");
  const [leftB, setLeftB] = useState("-100%");
  const [rightB, setRightB] = useState("100%");

  useEffect(() => {
    if (active == "login") {
      setLeftA(0);
      setRightA("50%");
      setLeftB("-100%");
      setRightB("100%");
    } else {
      setLeftB("50%");
      setRightB(0);
      setLeftA("100%");
      setRightA("-100%");
    }
  }, [active]);

  const login = () => {
    let d = body;
    d.Phone = rfEmail.current.value.trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (d.Phone.length != 10)
      return setIsErr("Please enter a valid phone number!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    setLoading(true);
    fetch("/api/voters/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Login Failed");
        }
      })
      .then((data) => {
        if (data.success) {
          setIsErr(data.success);
          setToken(data.token);
          setLoading(false);
          setTimeout(() => {
            localStorage.setItem("fmgdfhgfhtkn", data.token);
            window.location.href = "/";
          }, 500);
        } else {
          setLoading(false);
          setIsErr(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsErr("Login failed");
      });
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("fmgdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="login" ref={ref}>
      <div className="container">
        <div style={{ opacity: active == "login" ? 1 : 0 }}>
          <div id="sign-in" className={"sign-in "} style={{ left: leftA }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <h1>Sign In</h1>
              <h6>{isErr}</h6>
              <Input ref={rfEmail} type="phone" label="Phone Number" />
              <Input ref={rfPassword} type="password" label="Password" />
              <p
                onClick={() => {
                  setActive("reset");
                  setSActive("reset");
                }}
              >
                Reset Password
              </p>
              <button
                onClick={() => {
                  login();
                }}
              >
                Sign In
              </button>
            </form>
          </div>
          <div className="right-panel" style={{ left: rightA }}>
            <div>
              <h1>Kajiado Market Polls</h1>
              <p>
                We are delighted to introduce you to the Market Leaders Election
                App, a platform designed to empower you to elect your
                representatives for effective leadership and representation in
                the County Government of Kajiado.
              </p>
              <button
                onClick={() => {
                  setActive("reset");
                  setSActive("register");
                }}
                className="hidden"
              >
                SIGN UP
              </button>
            </div>
          </div>
        </div>
        <div style={{ opacity: active == "reset" ? 1 : 0 }}>
          {sactive == "reset" && (
            <div className={"reset"} style={{ left: leftB }}>
              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <h1>Reset Password</h1> <h6>{isErr}</h6>
                <Input ref={rsEmail} type="email" label="Email" />
                <button
                  onClick={() => {
                    reset();
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          )}
          {sactive == "register" && (
            <div className={"reset"} style={{ left: leftB }}>
              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Register setActive={setActive} />
              </form>
            </div>
          )}
          <div className="left-panel" style={{ left: rightB }}>
            <div>
              <h1>Kajiado Market Polls</h1>
              <p>
                We are delighted to introduce you to the Market Leaders Election
                App, a platform designed to empower you to elect your
                representatives for effective leadership and representation in
                the County Government of Kajiado.
              </p>
              <button
                onClick={() => {
                  setActive("login");
                }}
                className="hidden"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

const Register = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [subcounties, setSubcounties] = useState([]);
  const [ssubcounties, setSsubcounties] = useState(null);
  const [wards, setWards] = useState([]);
  const [swards, setSWards] = useState(null);
  const [tp, setTp] = useState("Voter");
  const [markets, setMarkets] = useState([]);

  const categories = [
    "Mali kwa Mali",
    "New Clothes",
    "Mtumba",
    "Cereals",
    "Vegetables",
    "Fruits",
    "Wholesale",
    "Regular Shops",
    "Bar & Restaurant",
    "Hotel",
    "Services",
    "Other",
  ];

  const name = useRef();
  const gender = useRef();
  const type = useRef();
  const cat = useRef();
  const subcounty = useRef();
  const ward = useRef();
  const market = useRef();
  const nationalid = useRef(null);
  const id_photo = useRef(null);
  const pass_photo = useRef(null);
  const email = useRef();
  const phone = useRef();
  const position = useRef();
  const password = useRef();

  useEffect(() => {
    fetch(`/api/towns/subcounties`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setSubcounties(data);
        setSsubcounties(data[0]);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (ssubcounties) {
      fetch(`/api/towns/subcounty/${ssubcounties}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWards(data);
          setSWards(data[0]);
        })
        .catch((e) => {});
    }
  }, [ssubcounties]);

  useEffect(() => {
    if (swards) {
      fetch(`/api/towns/ward/${swards}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setMarkets(data);
        })
        .catch((e) => {});
    }
  }, [swards]);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const createUser = async () => {
    const body = {
      Name: name.current.value,
      Position: nationalid.current != null ? position.current.value : null,
      Phone: phone.current.value,
      Email: email.current.value.toLowerCase().trim(),
      SubCounty: subcounty.current.value,
      Category: cat.current.value,
      Role: type.current.value,
      Ward: ward.current.value,
      NationalID: nationalid.current != null ? nationalid.current.value : null,
      NationalID_Photo:
        id_photo.current != null
          ? id_photo.current.files.length > 0
            ? await convertFileToBase64(id_photo.current.files[0])
            : null
          : null,
      Pass_Photo:
        pass_photo.current != null
          ? pass_photo.current.files.length > 0
            ? await convertFileToBase64(pass_photo.current.files[0])
            : null
          : null,
      Gender: gender.current.value,
      Market: market.current.value,
      Password: password.current.value,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validatePassword(body.Password)) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (
        gender.current.value == "" ||
        name.current.value == "" ||
        type.current.value == "" ||
        cat.current.value == "" ||
        subcounty.current.value == "" ||
        ward.current.value == "" ||
        market.current.value == ""
      ) {
        result = false;
        setError("Fill all required fields!");
        setLoading(false);
        return result;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/voters/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setActive("login");
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="popup">
      <h1>Register</h1>
      <Input ref={name} type="text" label="Name *" />

      <div className="div2equal">
        <Select ref={gender} data={["Male", "Female"]} label="Gender *" />
        <Select ref={cat} data={categories} label="Voting Category *" />
      </div>

      <div className="div2equal">
        <Select
          setChanged={(e) => {
            setTp(e);
          }}
          ref={type}
          data={["Voter", "Candidate"]}
          label="Type *"
          value={tp}
        />
        <Input ref={email} type="email" label="Email (optional)" />
      </div>

      <Input ref={phone} type="number" label="Phone *" />

      <div className="div2equal">
        <Select
          setChanged={(e) => {
            setSsubcounties(e);
          }}
          ref={subcounty}
          data={subcounties}
          label="Sub Counties *"
        />
        <Select
          setChanged={(e) => {
            setSWards(e);
          }}
          ref={ward}
          data={wards}
          label="Wards *"
        />
      </div>
      <Select ref={market} data={markets} label="Market *" />
      {tp == "Candidate" && (
        <>
          <div className="divequal">
            <Input
              ref={nationalid}
              type="text"
              label="National ID No. *"
              value=" "
            />
            <Select
              ref={position}
              data={["Chairperson", "Treasurer", "Secretary"]}
              label="Position *"
            />
          </div>
          <div className="div2equal">
            <div className="input-container">
              <label className={`label up`} htmlFor="">
                ID Photo (front) *
              </label>
              <input ref={id_photo} type="file" accept=".png,.jpg,.jpeg" />
            </div>
            <div className="input-container">
              <label className={`label up`} htmlFor="">
                Passport Photo *
              </label>
              <input ref={pass_photo} type="file" accept=".png,.jpg,.jpeg" />
            </div>
          </div>
        </>
      )}
      <Input ref={password} type="password" label="Password *" />
      <h6>{error}</h6>
      <Button handleClick={createUser} value="Submit" />
    </div>
  );
};
